import React, { Component } from 'react';
import './Home.css';

class Home extends Component {
    render() {
        return(
            <div className="menu_home">
                <h1 className="title_home"> · <span className="segurosFont_home">carlosparente</span><span className="italic_home"> —<br/>mediação de seguros</span></h1>
                <p className="text_home">Página em desenvolvimento<br/><br/><br/><br/><br/></p>
            </div>
        );
    }
}

export default Home;
